* {
    font-family: 'Work Sans';
    // Definimos el tamaño base que tendrá la fuente
    font-size: 16px; //para Explorer 8 y anteriores
    font-size: 1rem;
}

@media (max-width: 767.9px) {

    // el css funciona cuando sea más pequeño del tamaño
    * {
        font-size: 12px; //para Explorer 8 y anteriores
        font-size: 0.8rem;
    }
}

html,
body {
    height: 100vh;
    width: 100vw;
    background-color: whitesmoke;
}

main {
    width: 100vw;
}

hr {
    width: 100%;
    margin-left: 3vh;
    background-color: $grey-dark;
    height: 1px;
}

// Estilo del div contenedor de toda la página, incluido header, nav y main
.webAcc__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // justify-content: space-between; //Alineación vertical
    // align-items: stretch; //Alineación horizontal
}

// Estilo del cuerpo de la home
.webAcc__main-content {
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
}

.webAcc__content-titulo {
    // Estilo para las páginas con título y contenido
    display: flex;
    flex-direction: column;
}

.webAcc__content-subtitulo {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {

    // el css funciona cuando sea más grande del tamaño
    .webAcc__content-subtitulo {
        display: none !important;
    }
}

.webAcc__content-contenido {
    // Estilo para las páginas con título y contenido
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 75vh;
    overflow-y: scroll; // scrol vertical en el contenido. El menú queda fijo
}

.webAcc__content-contenido-privado {
    // Estilo para las páginas con título y contenido
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 85vh;
    overflow-y: scroll; // scrol vertical en el contenido. El menú queda fijo
}

.webAcc__content-bloques {
    // Estilo para las páginas con bloques (publicaciones, medios)
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
}

.shadow {
    box-shadow: 2px 2px 10px $grey-dark;
}

.borde__redondeado {
    border-radius: 2px;
}

.texto__negrita {
    font-weight: bold;
}

.texto__cursiva {
    font-style: italic;
}

.texto__subrayado {
    text-decoration: underline;
}

.mb-5 {
    margin-bottom: 20px;
}

.ml-3 {
    margin-left: 10px;
}

.fondo-primary {
    background-color: $primary;
}

.fondo-primary-light {
    background-color: $primary-light;
}

.fondo-primary-extralight {
    background-color: $primary-extralight;
}

.fondo-whitesmoke {
    background-color: whitesmoke;
}

.fondo-grey-dark {
    background-color: $grey-dark;
}

.fondo-grey-light {
    background-color: $grey-light;
}

.fondo-black-transp {
    background-color: rgba(0, 0, 0, 0.3);
}

.fondo-white-transp {
    background-color: rgba(255, 255, 255, 0.7);
}

.fondo-transparente {
    background-color: transparent;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}