.pcgModal{
    position: fixed;
    z-index: 999;
    top:0;
    left:0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.75);
    display: none;
    justify-content: center;
    align-items: center;
}

.pcgModal__isOpen{
    display: flex;
}

.pcgModal__hideButton{
    display: none;
}

.pcgModal__container{
    position: relative;
    padding: 1rem;
    min-width: 320px;
    max-width: 480px;
    min-height: 200px;
    max-height: 600px;
    overflow-y: auto;
    background-color: whitesmoke;
}

.pcgModal__container-buttons{
    display: flex;
    justify-content: center;
}

.pcgModal__close{
    position: absolute;
    top: 1rem;
    right: 1rem;
}