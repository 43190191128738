.link{
    color:$grey-dark;
    text-decoration: none;
    cursor: pointer;
    // font-size: 1.4vw;
    
    transition: color .2s ease;
    
    &:hover{
        color: lighten($color: $grey-dark, $amount: 30);
    }
}

.link-underline{
    color:$grey-dark;
    text-decoration: none;
    
    transition: text-decoration .2s ease; //TODO: No funciona
    
    &:hover{
        text-decoration:underline;
    }
}