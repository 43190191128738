.interruptor{
  display: flex;
  flex-flow: row wrap;
  margin: 3px;
  align-items: center;
  justify-content: center;
}

.interruptor__check {
    vertical-align: middle;
    margin-left: 0.5em;
  }

.interruptor__label{
  font-size: 1rem;
}
    