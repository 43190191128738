.input__group{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 3px;
    margin-right: 3px;
    // width: 25%;
}

.label{
    color: $grey-dark;
    font-size: 1rem;
    margin-right: 5px;
}

.input{
    color: $grey-dark;
    border: 0px;
    border-radius: 3px;
    font-size: 1rem;

    &:focus{
        outline: none;
    }
}

.input-search{
    @extend .input;
    margin-right: 1vh;
}

@media (max-width: 767.9px){ // el css funciona cuando sea más pequeño del tamaño
    .label{
        display: none;
    }

    .input-search{
        color: $grey-dark;
        border: 0px;
        border-radius: 3px;
        font-size: 1rem;
        margin-left: 3vh;
    
        &:focus{
            outline: none;
        }
    }
}