.selectorBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    margin-left: 2vh;
}

%selectorBarButtonOptions {
    border-radius: 4px;
    color: $grey-dark;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1vh;
    margin-top: 1vw;
    overflow: hidden;
    background-color: white;
    width: 80%;
    height: auto;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: white, $amount: 10);
    }
}

.selectorBarButton {
    @extend %selectorBarButtonOptions
}

.selectorBarButton-seleccionado {
    @extend %selectorBarButtonOptions;
    background-color: $primary-light;

    &:hover {
        background-color: darken($color: $primary-light, $amount: 10);
    }
}

@media (max-width: 767.9px) {

    // el css funciona cuando sea más pequeño del tamaño
    .selectorBarButton-text {
        font-size: 0.6rem;
    }

    .selectorBar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: center;
        margin-left: 1vh;
        margin-bottom: 1vh;
    }
}