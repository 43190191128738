// Estilos para el hook usePcgTable

.pcgTable__container{
    // Contenedor 
    // height: 100%;
    width: 95%;
}

.pcgTable__title{
    color:$primary;
    font-size: 1.3rem;
}

.pcgTable__img-opcion{
    border: 0px;
    max-height: 30px;
    max-width: 30px;
    padding: 0px;
    background-color:transparent;

    transition: background-color .3s ease;

    &:hover{
        transform: scale(1.3);
        transition: scale .3s ease;
    }
}

.pcgTable__img{
    max-width: 60px;
    box-shadow: 1px 1px 5px $grey-dark;
}

.pcgTable__tr-selected{
    background-color: $primary-light;
}

.pcgTable__boton-add{
    // display: inline;
    align-items: right;
    background-color: $primary;
    border: none;
    box-shadow: 2px 2px 10px $grey-dark;
    border-radius: 100%;
    bottom: 25px;
    // font-size: 14px;
    padding: 10px;
    position: fixed;
    right: 25px;

    &:disabled{
        background-color: lighten($color: $primary, $amount: 15);
    }
    
    &:hover{
        background-color: darken($color: $primary, $amount: 15);
        color: white;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-left: 3vh;
    margin-right: 3vh;
}

thead {
    background-color: $grey-light;
}
  
thead th {
    text-align: center;
    border-bottom: 2px solid black;
}
  
thead button {
    border: 0;
    height: 43px;
    padding: 0.5em;
    margin-bottom: 1px;
    background-color:transparent;
}

thead button.ascending::before {
    content: '👇';
    display: inline-block;
    margin-right: 3px;
}
  
thead button.descending::before {
    content: '☝️';
    display: inline-block;
    margin-right: 3px;
}
  
tbody td {
    padding: 0.5em;
    border-bottom: 1px solid #ccc;
    overflow-y: scroll; // scrol vertical en el contenido. La cabecera de la tabla queda fija
}
  
tbody tr:hover {
    background-color: $primary-extralight;
    transition: background-color .3s ease;
}
  
// Estilo del cuerpo de la home
.webAcc__main-content{
    display:flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
}

.webAcc__content-titulo{ // Estilo para las páginas con título y contenido
    display: flex;
    flex-direction: column;
}

.webAcc__content-subtitulo{
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px){ // el css funciona cuando sea más grande del tamaño
    .webAcc__content-subtitulo{
        display: none !important;
    }
}

.webAcc__content-contenido{ // Estilo para las páginas con título y contenido
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 75vh;
    overflow-y: scroll; // scrol vertical en el contenido. El menú queda fijo
}

.webAcc__content-bloques{ // Estilo para las páginas con bloques (publicaciones, medios)
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
}

.shadow{
    box-shadow: 2px 2px 10px $grey-dark;
}

.borde__redondeado{
    border-radius: 2px;
}

.texto__negrita{
    font-weight: bold;
}

.texto__cursiva{
    font-style: italic;
}

.texto__subrayado{
    text-decoration: underline;
}

.mb-5{
    margin-bottom: 20px;
}

.ml-3{
    margin-left: 10px;
}

.fondo-primary{
    background-color: $primary;
}

.fondo-primary-light{
    background-color: $primary-light;
}

.fondo-whitesmoke{
    background-color: whitesmoke;
}

.fondo-grey-dark{
    background-color: $grey-dark;
}

.fondo-grey-light{
    background-color: $grey-light;
}

.fondo-black-transp{
    background-color: rgba(0, 0, 0, 0.3);
}

.fondo-white-transp{
    background-color: rgba(255, 255, 255, 0.7);
}

.fondo-transparente{
    background-color: transparent;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 1s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}