.galeria__filtros{
    display: flex;
    align-items: center;
}

.galeria__main-content{
    display: flex;
    flex-flow: row wrap;
    justify-content:space-evenly;
    align-items:flex-start;
    // overflow-y: scroll;
}

.galeria__item{
    padding: 3%;
    text-align: center;
    position: relative;
    // max-width: 50%;
}

.galeria__banner{
    display: flex;
    flex-flow: row wrap;
    justify-content:space-evenly;
    align-items:center;
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
    width: 100%;
}

.galeria__banner-fecha{
    max-width: 10vh;
    display: flex;
    flex-flow: column wrap;
    justify-content:space-evenly;
    margin-right: 3px;
}

.galeria__banner-info{
    width: 60%;
    display: flex;
    flex-flow: column wrap;
    justify-content:space-evenly;
}

.galeria__item-img{
    max-height: 20vh;
    max-width: 25vh;
    box-shadow: 2px 2px 10px $grey-dark;
    margin-bottom: 1vh;
    transition: transform .3s ease;
    
    &:hover{
        // transform: rotate(1turn);
        transform: scale(1.1);
    }
}

.galeria__item-img-nuevo{
    position: absolute;
    margin-bottom: 1vh;
    z-index: 1;
}
// parpadeo

.animated {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
   }
@-webkit-keyframes flash {
    0%, 50%, 100% {
    opacity: 1;
    }
    25%, 75% {
    opacity: 0;
    }
}
@keyframes flash {
    0%, 50%, 100% {
    opacity: 1;
    }
    25%, 75% {
    opacity: 0;
    }
}
.flash {
    -webkit-animation-name: flash;
    animation-name: flash;
}

.episodio__img{
    max-height: 8vh;
    max-width: 8vh;
    margin-bottom: 1vh;
    transition: transform .3s ease;
    
    &:hover{
        // transform: rotate(1turn);
        transform: scale(1.1);
    }
}

// ESTILOS PARA LA GALERIA DE LOGO DE LA BIO

@media (max-width: 767.9px){ // el css funciona cuando sea más pequeño del tamaño
    .galeria__logos{
        display: none !important;
    }

    .galeria__banner-fecha{
        max-width: 100% !important;
        display: flex;
        flex-flow: row wrap;
        justify-content:space-evenly;
        align-items: center;
    }
}
.galeria__logos{
    background-color: $grey-light;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
    text-align: center;
    padding: 1%;
    // height: 15vh;
    // width: auto;
    width: 15vh;
}

.galeria__logos-logo{
    width: 13vh;
    height: auto;
}