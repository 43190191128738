
.auth__main{
    align-items: center;
    display:flex;
    justify-content: center;
    margin:0px;
    height: 100vh;
    width: 100vw;
}

.auth__box-container{
    padding: 20px;
    width: 350px;
}

.auth__social-networks{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}
