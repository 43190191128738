.form__control{
    color: $grey-dark;
    border: 0px;
    border-radius: 3px;
    border: 1px solid $grey-light;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
    padding: .375rem .75rem;
    
    transition: border-bottom .3s ease;
    // Esta sentencia es lo mismo que crear otra clase en css del tipo .auth__input:focus
    &:focus{
        outline: none;
        border-color: $primary;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $primary;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}

.form__title{
    color: darken($color: $primary, $amount: 20);
}

.form__label-input{
    display: flex;
    flex-direction: column;
}

.form__label{
    color: darken($color: $primary, $amount: 20);
    width: 100%;
    margin-bottom: 5px;
}

.form__input{
    color: $grey-dark;
    border: 0px;
    border-radius: 3px;
    border: 1px solid $grey-light;
    font-size: 16px;
    margin-bottom: 10px;
    height: 25px;
    width: 100%;
    
    transition: border-bottom .3s ease;
    // Esta sentencia es lo mismo que crear otra clase en css del tipo .auth__input:focus
    &:focus{
        outline: none;
        border-color: $primary;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $primary;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}

.form__input-error{
    color: $grey-dark;
    border: 1px solid $red-pastel;
    margin-bottom: 2px;
}

.form__error{
    color: $red-dark;
    border-radius: 3px;
    font-size: 12px;
    height: 15px;
    margin-bottom: 10px;
}