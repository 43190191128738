.navbar {
    flex-direction: row;
    text-align: center;
}

@media (min-width: 768px) {

    // el css funciona cuando sea más grande del tamaño
    .navbar__group-movil {
        display: none;
    }

    .navbar__group {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .navbar__item {
        padding: 0.3em 0.3em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        color: whitesmoke;
        position: relative;
        letter-spacing: 1px;
        margin: 0;
        text-decoration: none;

        transition: background-color .3s ease;

        &:hover {
            color: whitesmoke;
            border-bottom: 5px solid $grey-light;
        }
    }

    .navbar__item-current {
        background-color: $grey-light;
        color: $grey-dark;
        border-bottom: 5px solid $grey-light;
        border-top: 5px solid $grey-light;

        &:hover {
            color: $grey-dark;
            // color: whitesmoke;
        }
    }
}

@media (max-width: 767.9px) {

    // el css funciona cuando sea más pequeño del tamaño
    .navbar__group {
        display: none;
    }

    .navbar__group-movil {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .navbar__item-movil {
        padding: 0.6em 0.6em;
        color: whitesmoke;
        position: relative;
        margin: 0px;
        text-decoration: none;

        transition: background-color .3s ease;

        &:hover {
            color: whitesmoke;
            border-bottom: 5px solid $grey-light;
        }
    }

    .navbar__item-movil-current {
        background-color: $grey-light;
        color: $grey-dark;
        border-bottom: 3px solid $grey-light;
        border-top: 3px solid $grey-light;
        text-decoration: none;

        &:hover {
            color: $grey-dark;
        }
    }
}

// PARTE PRIVADA DE LA WEB
.navbar__private-nombre {
    color: whitesmoke;
    align-items: center;
    text-align: center;
    margin-left: 5px;
}

.navbar__private-item-current {
    border-bottom: 3px solid $primary-light;
    border-top: 3px solid $primary-light;
    background-color: $primary-light;
    color: $grey-dark;
}