
// Colors
$primary: #618c61;
$primary-light: #daf1da;
$primary-extralight: #ebffeb;
$primary-dark: #375037;
// $grey-light: #c8cdcf;
$grey-light: #e1e4e5;
$grey-medium: #999b9b;
$grey-dark: #1E2022;
$red-pastel: #ff6f6f;
$red-dark: #ff4646;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

// Font
@font-face {
    font-family: 'Work Sans';
    // src: url('../../assets/fonts/Work_Sans/static/WorkSans-Light.ttf') format("truetype")
    // src: url('../../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format("truetype")
    src: url('../../assets/fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format("truetype")
}
