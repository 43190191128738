// Estilo del cuerpo de la página de próximos eventos
.eventos__main-content{
    display: flex;
    flex-flow: column;
    height: 87vh;
    padding: 10px;
} 

/* Modal del calendario */
.ReactModalPortal > div{
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

// Date picker
.react-datetime-picker input:focus{
    outline: none;
}

.react-datetime-picker__wrapper {
    border: 0px solid gray !important;
}