// .galeria__filtros{
//     display: flex;
//     align-items: center;
// }

.listaItems__item {
    padding: 3%;
    text-align: left;
    display: flex;
    // flex-direction: column;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-items: flex-start;
}

@media (min-width: 768px) {

    // el css funciona cuando sea más grande del tamaño
    .listaItems__banner-fecha {
        padding-left: 3%;
        padding-bottom: 3vh;
        font-size: 1.5rem;
        font-style: italic;
        color: $grey-dark;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .listaItems__item-titulo {
        text-align: center;
        font-weight: bold;
        padding-bottom: 1vh;
        font-size: 1.1rem;
    }
}

@media (max-width: 767.9px) {
    .listaItems__banner-fecha {
        padding-left: 3%;
        padding-bottom: 3vh;
        font-size: 1rem;
        font-style: italic;
        color: $grey-dark;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .listaItems__item-titulo {
        text-align: center;
        font-weight: bold;
        padding-bottom: 1vh;
        font-size: 0.9rem;
    }
}

.listaItems__main-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;

    // overflow-y: scroll;
}

.listaItems__item-info {
    width: 60%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
}


.listaItems__item-descripcion {
    text-align: center;
    padding-bottom: 1vh;
}

.listaItems__item-tipo {
    text-align: center;
    font-weight: bold;
    font-style: italic;
    padding-bottom: 1vh;
}