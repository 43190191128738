.parrafo__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

@media (max-width: 767.9px) {

    // el css funciona cuando sea más pequeño del tamaño
    .parrafo {
        font-size: 1rem;
        margin-left: 2vh;
        margin-right: 2vh;
        text-align: justify;
    }

    .parrafo__h1 {
        font-size: 1.5rem;
        margin-left: 2vh;
        margin-top: 1%;
        margin-bottom: 0;
        text-decoration: $grey-dark;
    }

    .parrafo__h2 {
        font-size: 1.3rem;
        margin-left: 2vh;
        margin-top: 1%;
        margin-bottom: 0;
        text-decoration: $grey-dark;
    }
}

@media (min-width: 768px) {

    // el css funciona cuando sea más grande del tamaño
    .parrafo {
        margin-left: 3vh;
        margin-right: 3vh;
        text-align: justify;
    }

    .parrafo__h1 {
        font-size: 1.9rem;
        margin-left: 3vh;
        margin-top: 1%;
        margin-bottom: 0;
        text-decoration: $grey-dark;
    }

    .parrafo__h2 {
        font-size: 1.7rem;
        margin-left: 3vh;
        margin-top: 1%;
        margin-bottom: 0;
        text-decoration: $grey-dark;
    }
}

.parrafo__label {
    margin-left: 3vh;
    text-decoration: $grey-dark;
    font-weight: bold;
}

.parrafo__valor {
    margin-left: 3vh;
    color: $primary;
    // text-decoration: lighten($color: $primary, $amount: 30);;
}

.parrafo__imagen {
    // padding: 10px;
    max-width: 20%;
    height: auto;
    box-shadow: 2px 2px 10px $grey-dark;
    margin: 0 3vh 0 3vh;
}

.parrafo__galeria-item-titulo {
    text-align: center;
    margin-top: 0px;
    font-weight: bold;
    font-style: italic;
    max-width: 25vh;
}

.parrafo__galeria-banner-item-titulo {
    text-align: center;
    margin-top: 0px;
    font-weight: bold;
    font-style: italic;
}

.parrafo__galeria-banner-item-fecha {
    color: $primary-dark;
    text-align: center;
    margin-top: 0px;
    font-weight: bold;
    font-style: italic;
}

.parrafo__galeria-banner-item-dias-titulo {
    text-align: center;
    margin-top: 0px;
    font-weight: bold;
    font-style: italic;
}

.parrafo__galeria-banner-item-dias {
    color: $primary-dark;
    text-align: center;
    margin-top: 0px;
    margin-right: 3px;
    margin-left: 3px;
    font-weight: bold;
    font-style: italic;
    font-size: 2rem;
}

.parrafo__galeria-item-info {
    text-decoration: $grey-dark;
    text-align: center;
    margin-top: 0px;
    font-size: 0.9rem;
    max-width: 25vh;
}

.parrafo__galeria-banner-item-info {
    text-decoration: $grey-dark;
    text-align: center;
    margin-top: 0px;
    font-size: 0.9rem;
}

.parrafo__galeria-item-info-mini {
    text-decoration: $grey-dark;
    text-align: center;
    margin-top: 0px;
    font-size: 0.8rem;
    max-width: 25vh;
}

.parrafo__galeria-item-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: $grey-dark;
    margin-top: 0px;
    font-size: 0.9rem;
}

@media (max-width: 767.9px) {

    // el css funciona cuando sea más pequeño del tamaño
    .parrafo__imagen {
        display: none !important;
    }

    .parrafo__galeria-item-link {
        display: none !important;
    }

    .parrafo__galeria-banner-item-info {
        display: none !important;
    }

    .parrafo__galeria-banner-item-dias {
        font-size: 1.2rem;
    }

    .parrafo__galeria-banner-item-titulo {
        display: none !important;
    }
}