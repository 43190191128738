.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    // height: 5vh;
}

.footer__item{
    color:white;
    padding: 0.5em 0.5em;
    font-size: 2vh;
}