.header__main-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 2vh;
    height: 4vh;
}

.header__nombre{

    align-items: center;
    text-align: center;
    margin-left: 5px;
}