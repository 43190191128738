.modal {
    //TODO: hacer que se ajuste de alto al contenido
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 650px;
    max-width: 500px;
    outline: none;
    padding: 10px;
}

.modal-fondo {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

.modal-audio-player{
    // align-items: center;
    // display: flex;
    // justify-content: center;
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 200px;
    max-width: 400px;
    outline: none;
    padding: 10px;
}