.bloque__columna {
    background-color: $grey-light;
    color: $grey-dark;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 75vh;
    margin-left: 2vh;
    margin-top: 1vw;
    min-width: 15vh;
    width: 20vw;
    border-radius: 5px;
    overflow-y: scroll;
}

.bloque__columna-max {
    background-color: $grey-light;
    color: $grey-dark;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 75vh;
    margin-left: 2vh;
    margin-top: 1vw;
    min-width: 15vh;
    border-radius: 5px;
    overflow-y: scroll;

    width: 20vw;
}

.bloque__galeria {
    background-color: $grey-light;
    color: $grey-dark;
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 80vw;
    min-width: 30vh;
    margin-top: 1vw;
    margin-left: 3vh;
    margin-right: 3vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 5px;
    overflow-y: scroll;
}

.bloque__galeria_100 {
    @extend .bloque__galeria;
    width: 97vw;
}

.bloque__filtro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
}

.bloque__filtro-campos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    flex-flow: row wrap;
}


.opcion__columna-episodios {
    background-color: $grey-light;
    color: $grey-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    margin-left: 10px;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
}

// ESTILOS PARA LOS BOTONES DE OPCIÓN DE BLOQUES
// Placeholder para la opción de botón
%opcion__boton {
    border-radius: 4px;
    color: $grey-dark;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2vw;
    overflow: hidden;
}

.opcion__boton-item {
    @extend %opcion__boton;
    background-color: white;
    width: 80%;
    height: auto;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: white, $amount: 10);
    }
}

.opcion__boton-item-seleccionado {
    @extend %opcion__boton;
    background-color: $primary-light;
    width: 80%;
    height: auto;

    &:hover {
        background-color: darken($color: $primary-light, $amount: 10);
    }
}

.opcion__boton-item-big {
    //Usado en episodios
    @extend %opcion__boton;
    width: 90%;
    height: 20vh;
}


@media (min-width: 768px) {
    // el css funciona cuando sea más grande del tamaño

    .opcion__boton-item-name {
        display: flex;
        // font-size: 1.1vw;
        font-size: 1rem;
        width: 60%;
        margin-left: 5px;
        // padding:2vw;
        justify-content: left;
    }

    .opcion__boton-item-img {
        display: flex;
        justify-content: center;
        width: 40%;
        height: 4vw;
        padding: 0.3vw;
        transition: background-color .3s ease;
    }


}

@media (max-width: 767.9px) {

    // el css funciona cuando sea más pequeño del tamaño
    .opcion__boton-item-name {
        display: none;
    }

    .opcion__boton-item-img {
        display: flex;
        justify-content: center;
        width: 10vh;
        height: 10vw;
        padding: 0.5vw;
        transition: background-color .3s ease;
    }

    .bloque__filtro-campos {
        justify-content: center;
    }
}

// ESTILOS PARA LOS BOTONES DE EPISODIOS (MEDIOS)
.episodios__container {
    background-color: $grey-light;
    color: $grey-dark;
    display: flex;
    height: 100vh;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
}



.episodio__body {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
}

.episodio__body-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0px;
}

.episodio__body-content {
    font-size: 10px;
}

.episodio__date {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.episodio__date h4 {
    margin-bottom: 0px;
}

.episodio__date span {
    font-size: 12px;
}

// .opcion__item{
//     background-color: white;
//     border-radius: 4px;
//     color: $grey-dark;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     width: 150px;
//     height: 50px;
//     margin-top: 20px;
//     overflow:hidden;
//     box-shadow: 2px 2px 10px $grey-dark;
// }

// .opcion__item-img{
//     background-color: white;
//     border-radius: 4px;
//     color: $grey-dark;
//     display:flex;
//     flex-direction: row;
//     justify-content:center;
//     width: 150px;
//     height: 50px;
//     margin-top: 20px;
//     overflow:hidden;
//     box-shadow: 2px 2px 10px $grey-dark;
// }
// .opcion__img-sin-margen{
//     display: flex;
//     width: 10%;
//     height: 100%;
//     padding-right: 35px;
// }

// .opcion__img{
//     display: flex;
//     justify-content:center;
//     width: 10%;
//     height: 90%;
//     padding:2px;
// }
// .opcion__item-name{
//     //    display: flex;
//     font-size: 14px;
//     font-weight: bold;
//     text-align: center;
//     margin-left: 50px;
//     margin-right: 2px;
// }