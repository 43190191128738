.socialBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding-right: 15px;
    // padding-left: 15px;
    margin-right: 15px;
}

.socialBar__block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.socialBar__item {
    transition: transform .3s ease;
    padding-left: 0.2rem;

    &:hover {
        // transform: rotate(1turn);
        transform: scale(1.3);
    }
}