
.boton{
    border: none;
    color: white;
    cursor: pointer;
    font-size: 12px;
    padding: 7px 10px 7px 10px;
    // margin-left: 1vw;
    margin-right: 1vw;
    width: 6vw;

    &:focus{
        outline: none;
    }
}

.boton-icono{
    border: none;
    padding: 0px;

}

.boton-solid-grey{
    background-color: $grey-light;
    border-radius: 2px;
    color: whitesmoke;
    
    &:hover{
        color: darken($color: whitesmoke, $amount: 25);
    }
}

.boton-primary{
    background-color: $primary;
    border-radius: 2px;
    
    &:disabled{
        background-color: lighten($color: $primary, $amount: 15);
    }

    &:hover{
        background-color: darken($color: $primary, $amount: 15);
    }
}

.boton-primary-danger{
    background-color: $red-pastel;
    border-radius: 2px;
    
    &:disabled{
        background-color: lighten($color: $red-pastel, $amount: 15);
    }

    &:hover{
        background-color: darken($color: $red-pastel, $amount: 15);
    }
}

.boton-outline-primary{
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 2px;
    color: $primary;
    
    &:disabled{
        background-color: lighten($color: $primary, $amount: 15);
    }
    
    &:hover{
        background-color: darken($color: $primary, $amount: 15);
        color: white;
    }
}


.boton-plano-primary{
    background-color: transparent;
    border: 0px;
    color: whitesmoke;
    
    &:hover{
        color: darken($color: $primary, $amount: 25);
    }
}

.boton-tabla-editar{
    background-color: transparent;
    padding: 3px;
    
    &:disabled{
        background-color: lighten($color: $primary, $amount: 15);
    }
    
    &:hover{
        border-radius: 2px;
        border: 1px solid green;
        // background-color: darken($color: green, $amount: 15);
    }
}

.boton-tabla-borrar{
    background-color: transparent;
    padding: 3px;
    
    &:disabled{
        background-color: lighten($color: $primary, $amount: 15);
    }
    
    &:hover{
        border-radius: 2px;
        border: 1px solid red;
    }
}

.boton-block{
    width: 100%;
}

// BOTONES FLOTANTES (Floating Action Buttons -> FAB)
.fab-right{
    border-radius: 100%;
    bottom: 25px;
    font-size: 14px;
    padding: 15px;
    position: fixed;
    right: 25px;
}

.fab-left{
    border-radius: 100%;
    bottom: 25px;
    font-size: 14px;
    padding: 15px;
    position: fixed;
    left: 25px;
}
//// BOTON DE GOOGLE

.google-btn {
    cursor: pointer;
    margin-top: 5px;
    width: 100%;
    height: 42px;
    background-color: $google-blue;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

    transition: box-shadow .3s ease;

    .google-icon-wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: white;
    }
    .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
    }
    .google-btn-text {
        float: right;
        margin: 14px 100px 0 0;
        color: white;
        font-size: 14px;
        letter-spacing: 0.2px;
    }
    &:hover {
        box-shadow: 0 0 6px $google-blue;
    }
    &:active {
        background: $button-active-blue;
    }
}