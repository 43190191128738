
// Estilos de la foto y de la clase para responsive
.webAcc__img img{
    width: 100%;
    height: auto;
}

@supports(object-fit: cover){
    .webAcc__img img{
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
}

//Estilo del texto
.webAcc__texto {
    position: absolute;
    // top: 120px;
    top: 20%;
    left: 5%;
}

.webAcc__texto-titulo {
    font-size: 3vw;
    font-weight: 1200;
    color:$grey-dark;
}
.webAcc__texto-subtitulo {
    font-size: 2vw;
    color:$grey-dark;
}